import React from "react";
import Skeleton from "react-loading-skeleton";

interface Props {
  count?: number;
  className?: string;
}
export default function ProductCardSkeleton({ count = 1, className }: Props) {
  return (
    <div className={className ?? "col-span-12 md:col-span-6 lg:col-span-4 border"}>
      <Skeleton width={"100%"} className={"h-[17.5rem]"} />
      <div className={"py-2.5"}>&zwnj;</div>
      <Skeleton width="20%" height={20} className={"mb-2.5"} />
    </div>
  );
}
