import clsx from "clsx";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useState } from "react";

import { SidebarDataInterface } from "@/interfaces/Sidebar/SidebarInterface";

export const Categories = ({ categories, exactHandle }: { categories: SidebarDataInterface[]; exactHandle?: boolean }) => {
  const pathName = usePathname();
  const [showMore, setShowMore] = useState<boolean>(false);

  return (
    <>
      <h2 className="font-medium mb-2 text-kodi-blue text-lg">Kategorien</h2>
      {categories.map((collapse: SidebarDataInterface, index: number) => {
        const redirect = exactHandle ? (collapse.handle as string) : `/c/${collapse.handle}`;
        const inPath: boolean = pathName.includes(collapse.handle as string);

        if (!showMore && index > 10) {
          return <></>;
        }

        return (
          <div className={clsx("mb-1.5", inPath && "underline", !inPath && "bg-white")} key={index}>
            <Link href={redirect} className="hover:underline">
              <span className={`tracking-wide font-medium text-sm text-kodi-gray-600`}>
                <span className="mr-1">{collapse.name}</span>
              </span>
            </Link>
          </div>
        );
      })}

      {!showMore && categories.length > 10 && (
        <div className={clsx("underline mb-1.5 cursor-pointer")}>
          <span className={`tracking-wide font-medium text-sm text-kodi-gray-600`}>
            <span className="mr-1" onClick={() => setShowMore(!showMore)}>
              Mehr anzeigen
            </span>
          </span>
        </div>
      )}

      {showMore && (
        <div className={clsx("underline mb-1.5 cursor-pointer")}>
          <span className={`tracking-wide font-medium text-sm text-kodi-gray-600`}>
            <span className="mr-1" onClick={() => setShowMore(!showMore)}>
              Weniger anzeigen
            </span>
          </span>
        </div>
      )}

      {!categories.length && (
        <div className={clsx("mb-1.5")}>
          <span className={`tracking-wide font-medium text-sm text-kodi-gray-600`}>
            <span className="mr-1">Keine Kategorien gefunden</span>
          </span>
        </div>
      )}
    </>
  );
};
