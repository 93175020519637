"use client";

import { useCart, useProducts } from "medusa-react";
import React, { useEffect, useState } from "react";

import { getWeek, ProductHighlight, requestWeeklyOffer } from "@/components/pages/weeklyOffers/Offers";
import { ScrollContainer } from "@/components/Scroll/Container";
import { SliderProduct } from "@/components/Slider/Product/components/SlideProduct";

export const WeeklyOfferProducts = () => {
  const [productsIds, setProductsIds] = useState<ProductHighlight>({ totals: 0, ids: [] });

  useEffect(() => {
    const week = getWeek();

    requestWeeklyOffer(week, 0, 5).then(({ ids, totals }) => {
      setProductsIds({ totals: totals, ids: ids });
    });
  }, []);

  return (
    <div className={"relative w-full overflow-y-auto scrollbar-width px-2 md:px-0 md:pr-2 py-2 2xl:py-0"}>
      <ScrollContainer>
        <ProvideSliderProduct ids={productsIds.ids} />
      </ScrollContainer>
    </div>
  );
};

export const ProvideSliderProduct = ({ ids }: { ids: string[] }) => {
  const { cart } = useCart();
  const { products } = useProducts(
    {
      id: ids,
      region_id: cart?.region_id,
      expand: "variants,variants.prices",
    },
    { enabled: !!cart?.region_id },
  );

  if (!ids.length) {
    return <></>;
  }

  return (
    <>
      {products?.map((product, index) => {
        return <SliderProduct product={product} key={index} isFirstItem={index === 0} isLastItem={index + 1 < products.length} />;
      })}
    </>
  );
};
