import { PricedProduct } from "@medusajs/medusa/dist/types/pricing";

import { getWeek } from "@/components/pages/weeklyOffers/Offers";

interface Props {
  product: PricedProduct;
  className?: string;
  testId?: string;
}

interface LabelInterface {
  identifier: string;
  prefix: string;
  className: string;
}

interface Props {
  product: PricedProduct;
  position?: ProductFlagPosition;
}

export enum ProductFlagPosition {
  topLeft = "top-left",
  bottomRight = "bottom-right",
}

const WEEKLY_OFFER = "weekly_offer";

const LABELS = [
  { identifier: WEEKLY_OFFER, prefix: "Wochenangebote", className: "bg-kodi-magenta" },
  { identifier: "newest", prefix: "Neu", className: "bg-kodi-blue" },
] as LabelInterface[];

const LabelItem = ({ label, testId, product }: { label: LabelInterface } & Props) => (
  <div className={`px-3.5 py-0.5 w-fit uppercase text-xs sm:text-base ${label.className}`} data-testid={`${testId}${product.id}${label.identifier}`}>
    <span className="">{label.prefix}</span>
  </div>
);

export default function ProductFlags({ product, position }: Props) {
  switch (position) {
    case "bottom-right":
      return <BottomRightPosition product={product} />;
    case "top-left":
    default:
      return <TopLeftPosition product={product} />;
  }
}

const BottomRightPosition = ({ product }: { product: PricedProduct }) => {
  return (
    <div className="absolute text-white overflow-hidden z-20 grid gap-1.5 bottom-0 right-0">
      {product?.variants?.length > 1 ? (
        <LabelItem label={{ prefix: "weitere Varianten verfügbar", className: "bg-kodi-magenta", identifier: "more_variants" }} product={product} />
      ) : null}
    </div>
  );
};

const TopLeftPosition = ({ product }: { product: PricedProduct }) => {
  if (typeof product?.metadata !== "object") {
    return null;
  }

  return (
    <div className="absolute text-white overflow-hidden z-20 grid gap-1.5 top-0 left-0">
      {LABELS.map((label) => {
        const hasLabel = Object.prototype.hasOwnProperty.call(product.metadata, label.identifier) ? !!product.metadata?.[label.identifier] : false;

        if (hasLabel) {
          if (label.identifier === WEEKLY_OFFER && getWeek() !== product.metadata?.weekly_offer) {
            return null;
          }

          return <LabelItem key={`label-${label.identifier}`} className={label.className} label={label} product={product} />;
        }

        return null;
      })}
    </div>
  );
};
