import { useRouter } from "next/navigation";
import React, { useState } from "react";

import { DropdownButton } from "@/components/Button/DropdownButton";

export const SortButton = ({ setSort }: { setSort: React.Dispatch<React.SetStateAction<string>> }) => {
  const [title, setTitle] = useState<string>("");
  const router = useRouter();

  const handleClick = (key: string, value: string) => {
    router.push("?page=1");
    setTitle(value);
    setSort(key);
  };

  return (
    <div className="border-y border-gray-300 py-3 flex gap-4 items-center">
      <div>
        <p className="text-xs md:text-base uppercase font-medium">Sortieren nach</p>
      </div>
      <div>
        <DropdownButton
          closeOnClick={true}
          title={<span className="mr-5 md:mr-10 lg:mr-16">{title.length ? title : "wählen"}</span>}
          className="text-black py-1 md:py-2 w-full cursor-pointer hover:bg-gray-200"
          colorClasses="bg-white border"
        >
          <li className="block px-4 py-2 hover:bg-gray-300 font-medium cursor-pointer" onClick={() => handleClick("title", "Name Aufsteigend")}>
            Name Aufsteigend
          </li>
          <li className="block px-4 py-2 hover:bg-gray-300 font-medium cursor-pointer" onClick={() => handleClick("-title", "Name Absteigend")}>
            Name Absteigend
          </li>
          <li
            className="block px-4 py-2 hover:bg-gray-300 font-medium cursor-pointer"
            onClick={() => handleClick("variants.prices.amount", "Preis Aufsteigend")}
          >
            Preis Aufsteigend
          </li>
          <li
            className="block px-4 py-2 hover:bg-gray-300 font-medium cursor-pointer"
            onClick={() => handleClick("-variants.prices.amount", "Preis Absteigend")}
          >
            Preis Absteigend
          </li>
        </DropdownButton>
      </div>
    </div>
  );
};
