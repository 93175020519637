"use client";

import { ReadonlyURLSearchParams } from "next/dist/client/components/navigation";
import { useSearchParams } from "next/navigation";

export const usePagination = (limit: number, searchParams?: ReadonlyURLSearchParams) => {
  const _searchParams = useSearchParams();

  const parameters = !searchParams ? _searchParams : searchParams;

  const getPageNumber = () => {
    const page = parameters?.get("page");

    if (!page?.length) {
      return 1;
    }

    return parseInt(page);
  };

  const getOffset = () => {
    const page = getPageNumber();

    if (!page) {
      return 0;
    }

    return page * limit - limit;
  };

  const getLimit = () => limit;

  return { getPageNumber, getOffset, getLimit };
};
