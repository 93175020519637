import { medusaClient } from "@/lib/config";

export async function loadBreadcrumbs(categoryId: string) {
  if (categoryId) {
    return await medusaClient.productCategories
      .retrieve(categoryId, {
        expand:
          "parent_category,parent_category.parent_category,parent_category.parent_category.parent_category,parent_category.parent_category.parent_category.parent_category,parent_category.parent_category.parent_category.parent_category.parent_category",
      })
      .then(({ product_category }) => {
        return product_category;
      })
      .catch((error) => {
        console.error("Failed to load product category: " + categoryId + " from medusa.", error);
        return undefined;
      });
  }
  return undefined;
}
