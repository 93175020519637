"use client";

import clsx from "clsx";
import Link from "next/link";
import { useSearchParams } from "next/navigation";
import React from "react";

interface PaginationInterface {
  limit: number | undefined;
  totals: number | undefined;
}

const Items = ({ totals, limit }: { totals: number | undefined; limit: number | undefined }) => {
  const searchParams = useSearchParams();
  const currentPage = searchParams.get("page");
  const parsedCurrentPage = parseInt(currentPage ?? "1");
  const items = [];

  if (!totals || !limit) {
    return <></>;
  }

  const lastPage = parseInt((totals / limit).toFixed(0));

  for (let i = 0; i < totals / limit; i++) {
    if (parsedCurrentPage - 10 <= i - 6 && parsedCurrentPage + 10 >= i + 8) {
      items.push(<Item pageNumber={i + 1} isSelected={parsedCurrentPage === i + 1} key={i} />);
    }
  }

  return (
    <>
      {parsedCurrentPage >= 5 && <Item pageNumber={1} isSelected={parsedCurrentPage === 1} />}
      {items}
      {lastPage - parsedCurrentPage >= 4 && <Item pageNumber={lastPage} isSelected={parsedCurrentPage === lastPage} />}
    </>
  );
};

const Item = ({ pageNumber, isSelected }: { pageNumber: number; isSelected: boolean }) => (
  <li>
    <Link
      href={`?page=${pageNumber}`}
      className={clsx(
        "flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-200 hover:text-kodi-blue-hover",
        {
          "text-kodi-blue !bg-gray-100": isSelected,
        },
      )}
    >
      {pageNumber}
    </Link>
  </li>
);

export const Pagination = ({ limit, totals }: PaginationInterface) => {
  return (
    <nav aria-label="Page navigation example">
      <ul className="flex items-center -space-x-px h-8 text-sm">
        <Items totals={totals} limit={limit} />
      </ul>
    </nav>
  );
};

export const ViewedProducts = ({ limit, pageNumber, totals }: { limit: number; pageNumber: number; totals: number }) => {
  const pageLimit = limit * pageNumber;

  return (
    <p className="font-medium">
      Sie haben <span className="text-kodi-blue font-bold">{pageLimit > totals ? totals : pageLimit}</span> /{" "}
      <span className="text-kodi-blue font-bold">{totals}</span> Produkte angesehen
    </p>
  );
};
