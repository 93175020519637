"use client";

import { useCart, useProducts } from "medusa-react";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";

import { SearchParamsInterface } from "@/app/(main)/(padding)/wochenangebote/page";
import { SortButton } from "@/components/Button/SortButton";
import { Categories } from "@/components/pages/category";
import { ProductListingTitle, RenderSkeleton } from "@/components/pages/category/Products";
import { ThemeWorldInterface } from "@/components/pages/themeWorld";
import { provideThemeWorldsCategories, ThemeWorldBreadCrumbs, WEEKLY_OFFER_HANDLE } from "@/components/pages/themeWorld/ThemeProducts";
import { Pagination, ViewedProducts } from "@/components/Pagination";
import { ProductCard } from "@/components/Product/ProductCard";
import { medusaClient } from "@/lib/config";
import { usePagination } from "@/lib/hooks/use-pagination";
import { ProductPreviewType } from "@/types/global";

export interface ProductHighlight {
  totals: number;
  ids: string[];
}

export const getWeek = () => {
  return `KW${moment().isoWeek()}`;
};

export const requestWeeklyOffer = (week: string, offset: number | string, limit: number | string) => {
  return medusaClient.client.request("GET", `/store/highlights/${week}/${offset}/${limit}`);
};

export const Offers = ({ searchParams, themeWorlds }: SearchParamsInterface & { themeWorlds: ThemeWorldInterface[] }) => {
  const { getLimit, getOffset, getPageNumber } = usePagination(21);
  const [sort, setSort] = useState<string>("");
  const { cart } = useCart();
  const [productsIds, setProductsIds] = useState<ProductHighlight>({ totals: 0, ids: [] });

  useEffect(() => {
    const week = getWeek();

    requestWeeklyOffer(week, getOffset(), getLimit()).then(({ ids, totals }) => {
      setProductsIds({ totals: totals, ids: ids });
    });
  }, [getLimit(), getOffset(), searchParams]);

  return (
    <>
      <div className="mb-10 pl-4 lg:block hidden">
        <ThemeWorldBreadCrumbs isWeeklyOffer={true} themeWorld={prepareWeeklyOffer() as unknown as ThemeWorldInterface} />
      </div>

      <div className="grid grid-cols-12">
        <div className="col-span-12 lg:col-span-3 mt-10 px-4 lg:block hidden">
          <Categories exactHandle={true} categories={provideThemeWorldsCategories(themeWorlds)} />
        </div>
        <div className="col-span-12 lg:col-span-9 px-4">
          <ProductListingTitle />

          <div className="grid grid-cols-12 gap-3">
            <div className="col-span-12 mt-3 border-y border-gray-300">
              <SortButton setSort={setSort} />
            </div>

            <ProvideProductCardIds ids={productsIds.ids} regionId={cart?.region_id} sort={sort} />
          </div>

          <div className="flex justify-between mt-14">
            <div>
              <ViewedProducts limit={getLimit()} pageNumber={getPageNumber()} totals={productsIds.totals ?? 0} />
            </div>
            <Pagination limit={getLimit()} totals={productsIds.totals} />
          </div>
        </div>
      </div>
    </>
  );
};

const prepareWeeklyOffer = () => {
  return {
    name: WEEKLY_OFFER_HANDLE.name,
    url_alias: {
      data: {
        attributes: {
          url_path: WEEKLY_OFFER_HANDLE.handle,
        },
      },
    },
  };
};

export const ProvideProductCardIds = ({ ids, regionId, sort }: { ids: string[]; regionId: string | undefined; sort: string }) => {
  const { products, isLoading } = useProducts(
    {
      id: ids,
      region_id: regionId,
      order: sort.length ? sort : "title",
    },
    { enabled: !!regionId },
  );

  if (isLoading) {
    return <RenderSkeleton />;
  }

  if (!products?.length || !ids.length) {
    return (
      <div className="col-span-12">
        <p className="ml-5">Keine Produkte gefunden</p>
      </div>
    );
  }

  return (
    <>
      {products?.map((product, index) => {
        return (
          <div className="col-span-6 lg:col-span-4 border rounded-3xl" key={index}>
            <ProductCard className="" product={product as ProductPreviewType} />
          </div>
        );
      })}
    </>
  );
};
